import React, { useState, useEffect } from 'react';
import jekforms from '../../assets/img/jekforms.svg';
import axios from 'axios';
import buttonplus from '../../assets/img/buttonplus.svg';

import Adress from './../../adress.js';
import './Template3.css';

const Template3 = ({ buttonPressed, formularioId }) => {
  const [inputs, setInputs] = useState(['']);
  const [title, setTitle] = useState('');

  const handleAddInput = () => {
    if (inputs.length < 4) {
      setInputs(prevInputs => [...prevInputs, '']);
    }
  };

  const enviarInputs = async () => {
    try {
         // Filtrar opções vazias
         const filteredInputs = inputs.filter(input => input.trim() !== '');

         const options = filteredInputs.map(input => `${input}`);
   
         const dataToSend = {
           tipo: 3,
           titulo: title,
           formularioId: formularioId,
           checkbox: [{ opcoes: options }]
         };

      const response = await axios.post(`${Adress.backendUrl}/api/criar-Template/`, dataToSend);

      console.log('Inputs enviados para a base de dados');
      console.log('Valor do título:', title);
      console.log('Resposta da API:', response.data);

      setTitle('');
      setInputs(['']);
    } catch (error) {
      console.error('Erro ao enviar os inputs para a base de dados:', error);
    }
  };

  useEffect(() => {
    if (buttonPressed) {
      enviarInputs();
    }
  }, [buttonPressed]);

  const handleTitleChange = e => {
    setTitle(e.target.value);
  };

  const handleInputChange = (index, e) => {
    const newInputs = [...inputs];
    newInputs[index] = e.target.value;
    setInputs(newInputs);
  };

  return (
    <div className='Template3'>
      <img src={jekforms} alt="jekforms" className="jekformsT3" />
      <div className='contentT3'>
        <input type="text" name="Titulo" value={title} onChange={handleTitleChange} className="templateTitleT3" placeholder="Título" />
        <div className='inputContainer'>
          {inputs.map((input, index) => (
            <input key={index} type="text" value={input} placeholder="Opção" className="templateInputT3" onChange={e => handleInputChange(index, e)} />
          ))}
        </div>
        {inputs.length < 4 ? (
          <img src={buttonplus} alt="adiciona" className="Addbox" onClick={handleAddInput} />
        ) : (
          <img src={buttonplus} alt="adiciona" className="Addbox" onClick={handleAddInput} disabled />
        )}
      </div>
    </div>
  );
};

export default Template3;
