import React, { useState } from 'react';
import jekforms from '../../assets/img/jekforms.svg';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button/Button';

import Adress from './../../adress.js';
import './Template6op.css';

const Template6op = ({ titulo, imagens,formulario_id }) => {
  const [escolhaUsuario, setEscolhaUsuario] = useState(null);
  const navigate = useNavigate();
  const [mudar, setmudar] = useState(false)

  const handleImagemClick = (index) => {
   
    setEscolhaUsuario(index);
  };


  const enviarEscolhaParaBaseDeDados = async () => {
    try {
     
       if (escolhaUsuario !== null) {
        const escolha = imagens[escolhaUsuario].image; 
        
       const formData = new FormData();
      
      formData.append('formulario_id', formulario_id);
      formData.append('pergunta', titulo);
      formData.append('imagem', escolha);
       
        const response = await axios.post(`${Adress.backendUrl}/api/answer-Form/`, formData, {
      
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

       
        console.log('Escolha do usuário enviada para a base de dados:', escolha);
        console.log('Resposta da API:', response.data);
        setmudar(true)
        navigate(`/Show-Forms?formulario_id=${formulario_id}&change=${mudar}`);

      } else {
        console.error('Nenhuma escolha do usuário foi feita.');
      }
    } catch (error) {
      console.error('Erro ao enviar a escolha do usuário para a base de dados:', error);
    }
  };

  return (
    <div className='Templateop6'>
      <img src={jekforms} alt="jekforms" className="jekformsTOP6" />
      <div className='contentT6'>
        <h2 className='titulotop6'> <span className="blue-text">{titulo}</span> </h2>
        <div className="imagensWrapper">
          {imagens.map((imagem, index) => (
            <div  id={`Imagem-${index + 1}`} key={index} onClick={() => handleImagemClick(index)}>
              <img
                src={`${Adress.backendUrl}${decodeURIComponent(imagem.image)}`}
                alt={`Imagem-${index + 1}`}
                className={`imagens-${escolhaUsuario === index ? "selecionada" : ""}`}
              />
            </div>
          ))}
        </div>
        
      </div>
      <div className="Button-containerop6" onClick={enviarEscolhaParaBaseDeDados}>
            <Button className="Button" />
      </div>
    </div>
  );
};

export default Template6op;
