import React, { useState, useEffect } from 'react';  // Importe useState e useEffect
import axios from 'axios';  // Importe o módulo axios
import jekforms from '../../assets/img/jekforms.svg';

import Adress from './../../adress.js';
import './Template4.css';

const Template4 = ({ buttonPressed, formularioId }) => {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event) => {
    if (!buttonPressed) {
      setInputValue(event.target.value);
    }
  };

  useEffect(() => {
    if (buttonPressed && inputValue) {
      enviarInputs();
    }
  }, [buttonPressed]);

  const enviarInputs = async () => {
    try {
      console.log(formularioId);
      const response = await axios.post(`${Adress.backendUrl}/api/criar-Template/`, {
        tipo: 4, 
        titulo: inputValue,
        formularioId: formularioId,
      });
      console.log('Inputs enviados para a base de dados');
      console.log('Valor do input:', inputValue);
      console.log('Resposta da API:', response.data);
      setInputValue('');
    } catch (error) {
      console.error('Erro ao enviar os inputs para a base de dados:', error);
    }
  };


  return (
    <div className='Template4'>
      <img src={jekforms} alt="jekforms" className="jekformsT4" />
      <div className='contentT4'>

      <input type="text" className="TituloT4" placeholder="Título da pergunta" value={inputValue} onChange={handleInputChange} />
      </div>
    </div>
  );
};

export default Template4;
