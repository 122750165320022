import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import jekforms from '../../assets/img/jekforms.svg';
import createform from '../../assets/img/createform.svg';
import Logout from '../../components/LogOut/Logout';
import Active from '../../assets/img/Active.svg';
import Inactive from '../../assets/img/Inactive.svg';
import './Menupage.css';
import Lupa from '../../assets/img/lupa.jpg';
import { BiDotsVerticalRounded } from "react-icons/bi";
import { SlMagnifier } from "react-icons/sl";
import {RiDeleteBin6Line} from "react-icons/ri"
import {AiOutlineLink} from "react-icons/ai"
import Adressfront from './../../adressfront.js';
import {BiCommentDetail} from 'react-icons/bi'

const Menupage = () => {
  const [isUserActive, setIsUserActive] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [formularios, setFormularios] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const isUserActiveParam = params.get('is_active');
  const email = params.get('email');
  const [Tam, setTam] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState({});


  useEffect(() => {
    const checkUserActivation = async () => {
      try {
        setIsUserActive(Boolean(isUserActiveParam));
      } catch (error) {
        console.log('Error checking user activation:', error);
      }
    };

    checkUserActivation();
  }, [isUserActiveParam]);

  useEffect(() => {
    const getFormularios = async () => {
      console.log(email);
      try {
        const response = await axios.get('jekforms.vercel.app/api/get-formulario/', {
          params: { email: email }
        });
        const data = response.data;
        const formulariosData = data.map((item) => item.formulario);
        setFormularios(formulariosData);
        setTam(formulariosData.length)
        console.log(formulariosData)
        console.log(formulariosData.length);
      } catch (error) {
        console.log('Error retrieving formularios:', error);
      }
    };
  
    getFormularios();
  }, [email]);

  const handleDeleteForm = async (formularioId) => {
    try {
      await axios.delete(`jekforms.vercel.app/api/delete_Form/${formularioId}`);
      window.location.reload();
    } catch (error) {
      console.error('Erro ao excluir o formulário:', error);
    }
  };

  const toggleDropdown = (formularioId) => {
    setDropdownOpen(prevState => ({
      ...prevState,
      [formularioId]: !prevState[formularioId]
    }));

    
  };

  const handleCreateForm = () => {
    const CreateURL = `/create-forms?email=${email}`;
    window.location.href = CreateURL;
  };

  const handleSearchForm = () => {
    console.log('Searching for:', searchQuery);
    const filteredFormularios = formularios.filter((formulario) =>
      formulario.nome.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFormularios(filteredFormularios);
    setSearchQuery('');
  };
  
  const handleFormButtonClick = (formularioId) => {
    const FormularioURL = `/Show-Forms?formulario_id=${formularioId}`;
    window.location.href = FormularioURL;
  };

  const handleIconClick = (formularioId) => {
    const FormularioURL = `/Answer?formulario_id=${formularioId}&email=${email}`;
    navigate(FormularioURL); 
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <div className="container-Menu">
      <img src={jekforms} alt="jekforms logo" className="jekformsM" />

      <div className='LogoutM'>
        <Logout/>
      </div>
      
      <input
        className="pesquisa"
        type="text"
        value={searchQuery}
        onChange={handleInputChange}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            handleSearchForm();
          }
        }}
        placeholder="Pesquisa"
      />
      <div className='lupa'>
        <SlMagnifier className='lupa'/>
      </div>

      <div className='criabtn'>
        <div className='btn-wrapper'>
          <img src={Active} alt="Ativo" className="Activebtn"  />
          <img src={Inactive} alt="Inativo" className="Inactivebtn"  />
        </div>
        
        <button className='createbtn' onClick={handleCreateForm}>Create new form</button>
      </div>

      <div className='column-layout'>
        {formularios.map((formulario, index) => (
          <button
            key={formulario.id}
            className={`formulario-button-${index % 2 === 0 ? 'par' : 'impar'}`}
          >
            <div className='button-content'  onClick={() => handleFormButtonClick(formulario.id)}>
              {formulario.nome}
            </div>
            <div className={`ellipsis-`} onClick={() => toggleDropdown(formulario.id)}>
              <BiDotsVerticalRounded />
            </div>
            {dropdownOpen[formulario.id] && (
              <div className="dropdown-content">
                <p id='ic1' onClick={() => handleDeleteForm(formulario.id)}><RiDeleteBin6Line /></p>
                <p id='ic2' onClick={() => alert(`${Adressfront.frontendUrl}/Show-Forms?formulario_id=${formulario.id}`)}><AiOutlineLink /></p>
                <p id='ic3' onClick={() => handleIconClick(formulario.id)}><BiCommentDetail/></p>
              </div>
            )}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Menupage;
