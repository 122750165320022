import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Adress from './../../adress.js';
import Template1op from '../../components/TemplatesOP/Template1op';
import Template2op from '../../components/TemplatesOP/Template2op';
import Template3op from '../../components/TemplatesOP/Template3op';
import Template4op from '../../components/TemplatesOP/Template4op';
import Template5op from '../../components/TemplatesOP/Template5op';
import Template6op from '../../components/TemplatesOP/Template6op';
import Template7op from '../../components/TemplatesOP/Template7op';

import './FormShow.css';

function FormShow( change) {
  const [templateData, setTemplateData] = useState([]);
  const [currentTemplateIndex, setCurrentTemplateIndex] = useState(0); // Inicialmente, exibimos o primeiro template
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const formularioId = params.get('formulario_id');
  const mudarTemplate = params.get('change');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTemplateData = async () => {
      try {
        const response = await fetch(`${Adress.backendUrl}/api/get-Template/${formularioId}/`);
        if (!response.ok) {
          throw new Error('Não foi possível obter os dados do template');
        }
        const data = await response.json();
        setTemplateData(data);
        console.log('Dados dos Templates recebidos:', data);
      } catch (error) {
        console.error('Erro ao obter os dados dos templates:', error);
      }
    };

    fetchTemplateData();
  }, [formularioId]);

 
  useEffect(() => {
    if (mudarTemplate) {
      goToNextTemplate();
    }
  }, [mudarTemplate]);

  const goToNextTemplate = () => {
    const nextIndex = currentTemplateIndex + 1;
    if (nextIndex < templateData.length) {
      setCurrentTemplateIndex(nextIndex);
      console.log(change)
      params.set('change', 'False');
      
    } else {
      return <h1>Cabo</h1>;
    }
  };

  const renderCurrentTemplate = () => {
    if (templateData.length === 0 || currentTemplateIndex >= templateData.length) {
      return <h1>Carregando os dados do template...</h1>;
    }

    const currentTemplate = templateData[currentTemplateIndex];

    switch (currentTemplate.tipo) {
      case 1:
        console.log(mudarTemplate)
        return <Template1op formulario_id={formularioId} titulo={currentTemplate.titulo} imagem={currentTemplate.imagem}  />;
      case 2:
        return <Template2op formulario_id={formularioId} titulo={currentTemplate.titulo} />;
      case 3:
        return <Template3op titulo={currentTemplate.titulo} perguntasCheckbox={currentTemplate.perguntas_checkbox} formulario_id={formularioId} />;
      case 4:
        return <Template4op titulo={currentTemplate.titulo} formulario_id={formularioId} />;
      case 5:
        return <Template5op titulo={currentTemplate.titulo} formulario_id={formularioId} />;
      case 6:
        return <Template6op titulo={currentTemplate.titulo} imagens={currentTemplate.imagens_template6} formulario_id={formularioId} />;
      case 7:
        return <Template7op titulo={currentTemplate.titulo} formulario_id={formularioId} />;
      default:
        return null;
    }
  };

  return (
    <div className="FormShow">
      {renderCurrentTemplate()}
    </div>
  );
}

export default FormShow;
