import React, { useState, useEffect } from 'react';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import './Loginpage.css';
import jekforms from '../../assets/img/jekforms.svg';

function Loginpage({ setEmail }) {
  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLogin = async (profile) => {
    try {
      const res = await axios.post('jekforms.vercel.app/api/login/', {
        username: profile.name,
        email: profile.email
      });
      console.log(res.data);

      // Chamar a função para ativar o usuário
      await axios.put(`jekforms.vercel.app/api/user-activation/${res.data.user_id}/`);

      setIsLoggedIn(true);
      const menuURL = `/menu?is_active=${res.data.is_active}&email=${profile.email}`;
      window.location.href = menuURL; // Redireciona para a página do menu com os parâmetros is_active e email
    } catch (error) {
      console.log(profile);
      console.log(error);
    } 
  };

  const login = useGoogleLogin({
    clientId: '863812812346-1kn9rp3294v4dnb9gn2rr8tf33jpbhmj.apps.googleusercontent.com',
    onSuccess: async (response) => {
      setUser(response);
      if (response) {
        axios
          .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${response.access_token}`)
          .then((res) => {
            setProfile(res.data);
            setEmail(res.data.email); 
            handleLogin(res.data);
          })
          .catch((err) => console.log(err));
      }
    },
    onError: (error) => console.log('Login Failed:', error)
  });

  return (
    <div className="container">
      <img src={jekforms} alt="jekforms logo" className="jekformsL" />

      <div className="signIn">
        <button className="google-btn" onClick={() => login()}>
          Iniciar Sessão
        </button>
      </div>

      <div className="LogoG"></div>
    </div>
  );
}

export default Loginpage;
