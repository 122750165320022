import React, { useState, useEffect } from 'react';
import axios from 'axios';
import jekforms from '../../assets/img/jekforms.svg';
import Continuarbtn from '../../assets/img/Continuarbtn.svg';
import { useNavigate } from 'react-router-dom'; 
import './Template2op.css';

const Template2op = ({ formulario_id, titulo }) => {
  const [inputValue, setInputValue] = useState('');
  const [mudar, setmudar] = useState(false)
  const navigate = useNavigate(); 


  const handleMudarTemplate = () => {
    console.log(formulario_id)
   
    navigate(`/Show-Forms?formulario_id=${formulario_id}&change={True}`);

  };

  return (
    <div className='Template2OP'>
      <img src={jekforms} alt="jekforms" className="jekformsOP2" />  
      
      <div className='contentOP2'>
       <h1>{titulo}</h1>  
       <button alt="Continua" className="ContinuarBtnOP2"  onClick={handleMudarTemplate}>Continuar</button>
      </div>
    </div>
  );
};

export default Template2op;
