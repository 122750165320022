import React, { useState, useEffect } from 'react';  
import axios from 'axios'; 
import jekforms from '../../assets/img/jekforms.svg';
import Calendario from '../../assets/img/Calendario.svg'

import Adress from './../../adress.js';
import './Template5.css'

const Template5 = ({ buttonPressed, formularioId }) => {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event) => {
    if (!buttonPressed) {
      setInputValue(event.target.value);
    }
  };

  useEffect(() => {
    if (buttonPressed && inputValue) {
      enviarInputs();
    }
  }, [buttonPressed]);

  const enviarInputs = async () => {
    try {
      console.log(formularioId);
      const response = await axios.post(`${Adress.backendUrl}/api/criar-Template/`, {
        tipo: 5, 
        titulo: inputValue,
        formularioId: formularioId,
      });
      console.log('Inputs enviados para a base de dados');
      console.log('Valor do input:', inputValue);
      console.log('Resposta da API:', response.data);
      setInputValue('');
    } catch (error) {
      console.error('Erro ao enviar os inputs para a base de dados:', error);
    }
  };

  return (
    <div className='Template5'>
      
      <img src={jekforms} alt="jekforms" className="jekformsT5" />

      <div className='contentT5'>
      <input type="text" name="Titulo" placeholder="Título da pergunta" value={inputValue} onChange={handleInputChange}/>
      </div>

      <img src={Calendario} alt="imagem de um calendario" className="Calendario" />
    </div>
  );
};

export default Template5;
