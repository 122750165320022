import React, { useState, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import './FormCreatepage.css';
import icon_1_1 from '../../assets/img/icon_1_1.png';
import icon_2_1 from '../../assets/img/icon_2_1.png';
import icon_3_1 from '../../assets/img/icon_3_1.png';
import icon_4_1 from '../../assets/img/icon_4_1.png';
import icon_5_1 from '../../assets/img/icon_5_1.png';
import icon_6_1 from '../../assets/img/icon_6_1.png';
import icon_7_1 from '../../assets/img/icon_7_1.png';
import jekforms from '../../assets/img/jekforms.svg';
import Modelos from '../../assets/img/Modelos.svg';
import Geral from '../../assets/img/Geral.svg';
import buttonplus from '../../assets/img/buttonplus.svg';
import Logout from '../../components/LogOut/Logout';
import Template1 from '../../components/Templates/Template1';
import Template2 from '../../components/Templates/Template2';
import Template3 from '../../components/Templates/Template3';
import Template4 from '../../components/Templates/Template4';
import Template5 from '../../components/Templates/Template5';
import Template6 from '../../components/Templates/Template6';
import Template7 from '../../components/Templates/Template7';

import Adress from './../../adress.js';

function FormsCreatepage() {
  const [modeloSelected, setModeloSelected] = useState(true);
  const [geralSelected, setGeralSelected] = useState(false);
  const [templateComponent, setTemplateComponent] = useState(null);
  const [numPages, setNumPages] = useState(1);
  const [buttonPressed, setButtonPressed] = useState(false);
  const [formularioNome, setFormularioNome] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formularioId, setFormularioId] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const email = params.get('email');

  const handleFormularioNomeChange = (event) => {
    setFormularioNome(event.target.value);
  };

  const criarFormulario = async () => {

    console.log(email);
    try {
      const response = await axios.post(`${Adress.backendUrl}/api/criar-form/`, { nome: formularioNome, email: email});
      const formularioId = response.data.id;
      setFormularioId(formularioId);
      console.log('ID do formulário criado:', formularioId);
    } catch (error) {
      console.error('Erro ao criar o formulário:', error);
    }
  };

  
  const handlePage = () => {
    setNumPages(prevNumPages => prevNumPages + 1);
  
    if (templateComponent) {
      const updatedTemplateComponent = React.cloneElement(templateComponent, {
        buttonPressed: true,
        formularioId: formularioId // Passa o ID do formulário como prop
      });
      console.log('Estado do botão:', buttonPressed);

      setTemplateComponent(updatedTemplateComponent);
    }
    setButtonPressed(false);

  };
  

  const handleButtonClick = (template) => {
    let templateComponent = null;
  
    switch (template) {
      case 'template1':
        templateComponent = <Template1 buttonPressed={buttonPressed} formularioId={formularioId} />;
        break;
      case 'template2':
        templateComponent = <Template2 buttonPressed={buttonPressed} formularioId={formularioId}/>;
        break;
      case 'template3':
        templateComponent = <Template3 buttonPressed={buttonPressed} formularioId={formularioId}/>;
        break;
      case 'template4':
        templateComponent = <Template4 buttonPressed={buttonPressed} formularioId={formularioId}/>;
        break;
      case 'template5':
        templateComponent = <Template5 buttonPressed={buttonPressed} formularioId={formularioId}/>;
        break;
      case 'template6':
        templateComponent = <Template6 buttonPressed={buttonPressed} formularioId={formularioId}/>;
        break;
      case 'template7':
        templateComponent = <Template7 buttonPressed={buttonPressed} formularioId={formularioId}/>;
        break;
      default:
        break;
    }
  
    setTemplateComponent(templateComponent);
    
  
    // Use o console.log para rastrear a mudança do estado do botão
    console.log('Estado do botão:', buttonPressed);
  };
  
  // Resto do código...
  
  
  
  
  

  function returnToMenu() {
    const createURL = `/menu?email=${email}`;
     navigate(createURL); // Navega para a página do menu com o parâmetro email
  }

  const handleModeloClick = () => {
    setModeloSelected(true);
    setGeralSelected(false);
  };

  const handleGeralClick = () => {
    setModeloSelected(false);
    setGeralSelected(true);
  };

  const handleFormularioSubmit = (event) => {
    event.preventDefault();
    criarFormulario();
    setFormSubmitted(true);
  };
  
  return (
    <div className="FormsC">
      <img src={jekforms} alt="jekforms logo" className="jekformsFC" onClick={returnToMenu} />

      <div className='LogoutFC'>
        <Logout />
      </div>

      <form onSubmit={handleFormularioSubmit}>
      {formSubmitted ? null : (
        <input
          type="text"
          className='Nomeform'
          name="nomeFormulario"
          value={formularioNome}
          onChange={handleFormularioNomeChange}
          placeholder="Nome do Formulário"
        />
      )}
      {formSubmitted ? null : <button type="submit" className='nomebtn'>Adicionar</button>}
    </form>

      {formSubmitted != false && (
        <>
       
      <div className='add-T'>  
          <div className="template-example">
         {formSubmitted && templateComponent}
        </div>
        <div className="add-page-button">
            <img src={buttonplus} alt="botão de mais" onClick={handlePage} />
          </div>
      </div>
      

          <div className="button-container">
          <button className={`Modelobtn-${modeloSelected ? 'select' : ''}`} onClick={handleModeloClick}>
            Modelo
          </button>
          
          <button className={`Geralbtn-${geralSelected ? 'select' : ''}`} onClick={handleGeralClick}>
            Geral
          </button>
        </div>

        
          <div className="line">
            <div className="scroll-container">
              <div className="image-container">
                <img src={icon_1_1}  alt="Ícone 1" onClick={() => handleButtonClick('template1')} />
                <img src={icon_2_1}  alt="Ícone 2" onClick={() => handleButtonClick('template2')} />
                <img src={icon_3_1}  alt="Ícone 3" onClick={() => handleButtonClick('template3')} />
                <img src={icon_4_1}  alt="Ícone 4" onClick={() => handleButtonClick('template4')} />
                <img src={icon_5_1}  alt="Ícone 5" onClick={() => handleButtonClick('template5')} />
                <img src={icon_6_1}  alt="Ícone 6" onClick={() => handleButtonClick('template6')} />
                <img src={icon_7_1}  alt="Ícone 7" onClick={() => handleButtonClick('template7')} />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default FormsCreatepage;
