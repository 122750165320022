import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import jekforms from '../../assets/img/jekforms.svg';
import logojek3 from '../../assets/img/logojek3.svg';
import Button from '../../components/Button/Button';

import Adress from './../../adress.js';
import './Template4op.css';

const Template4op = ({ titulo, formulario_id }) => {
  const [inputValue, setInputValue] = useState('');
  const [mudar, setmudar] = useState(false)
  const navigate = useNavigate();

  


const enviarInputs= async () => {
  try {

    const formData = new FormData();

    formData.append('formulario_id', formulario_id);
    formData.append('pergunta', titulo);
    formData.append('resposta', inputValue);

    const response = await axios.post(`${Adress.backendUrl}/api/answer-Form/`, formData);

    console.log(response.data);
    setInputValue('');
    setmudar(true)
    navigate(`/Show-Forms?formulario_id=${formulario_id}&change=${mudar}`);
  } catch (error) {
    console.error(error);
  }
};
  return (
    <div className='Templateop4'>
      <img src={jekforms} alt="jekforms" className="jekformsop4" />

      <div className='contentOP4'>
        <h2 className='titulo4'> <span className="blue-text">{titulo}</span></h2>
        <input
          type="text"
          value={inputValue}
          className="inputop4"
          onChange={(e) => setInputValue(e.target.value)} 
        />
       
      </div>
      <img src={logojek3} alt="logojek" className="logojek3" />

      <div className="Button-containerop4" onClick={enviarInputs}>
            <Button className="Button" />
      </div>

    </div>
  );
};

export default Template4op;
