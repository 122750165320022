import React, { useState, useEffect } from 'react';
import axios from 'axios';
import jekforms from '../../assets/img/jekforms.svg';
import Continuarbtn from '../../assets/img/Continuarbtn.svg';

import Adress from './../../adress.js';
import './Template2.css';

const Template2 = ({ buttonPressed, formularioId }) => {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event) => {
    if (!buttonPressed) {
      setInputValue(event.target.value);
    }
  };

  useEffect(() => {
    if (buttonPressed && inputValue) {
      enviarInputs();
    }
  }, [buttonPressed]);

  const enviarInputs = async () => {
    try {
      console.log(formularioId);
      const response = await axios.post(`${Adress.backendUrl}/api/criar-Template/`, {
        tipo: 2, 
        titulo: inputValue,
        formularioId: formularioId,
      });
      console.log('Inputs enviados para a base de dados');
      console.log('Valor do input:', inputValue);
      console.log('Resposta da API:', response.data);
      setInputValue('');
    } catch (error) {
      console.error('Erro ao enviar os inputs para a base de dados:', error);
    }
  };

  return (
    <div className='Template2'>
      <img src={jekforms} alt="jekforms" className="jekformsT2" />  
      
      <div className='contentT2'>
        <input type="text" name="Titulo" className="TemplateInputT2" placeholder="Título" value={inputValue} onChange={handleInputChange}/>
        <img src={Continuarbtn} alt="Continua" className="ContinuarBtnT2" />  
      </div>s
    </div>
  );
};

export default Template2;
