import React from 'react';
import Button from '../../components/Button/Button';
import jekforms from '../../assets/img/jekforms.svg';
import logojek from '../../assets/img/logojek.svg'

import './Inipage.css';

function Inipage() {

  return (
    <div className="Inipage">
      
      <div className='contentIni'>
        {/*icone jek*/}
        <div className='content-top'>
          <img src={jekforms} alt="jekforms" className="jekforms" /> 

          {/*caixa do texto*/}
            <div className='text-container'>
                <h1 className="title">
                  <span className="blue-text">Hey jeKer,</span> this is <span className="blue-text">jeK</span>forms!
                </h1>  
                <h2 className="texto">
                  Here you’ll be able to create forms for all the<br />events and activities you’re in charge of!
                </h2>
            </div>
          </div>

        {/*logo jek*/}  
          <img src={logojek} alt="logojek" className="logojeK" /> 
        
        

        {/*botão*/}  
          <div className="Button-container">
            <Button className="Button" url="/Login/" />
          </div>

      </div>  
      
    </div>
  );
}

export default Inipage;