import React from 'react';
import { Link } from 'react-router-dom';
import Next from '../../assets/img/Next.svg';
import './Button.css'; 

function Button({ url }) {
  return (
    <Link to={url} >
    <img src={Next} alt="Custom Button" className="Button" />
  </Link>
  );
}

export default Button;