import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import '../src/assets/css/global.css';
import Inipage from './pages/inipage/Inipage';
import Loginpage from './pages/loginpage/Loginpage';
import Menupage from './pages/Menupage/Menupage';
import FormCreatepage from './pages/FormCreatepage/FormsCreatepage';
import FormShow from './pages/FormShow/FormShow';
import Answer from './pages/AnswerPage/Answer'

function App() {
  const [email, setEmail] = useState('');
  const [change, setChange] = useState('');

  return (
    <BrowserRouter>
      <Routes>
        <>
          <Route path="/" element={<Inipage />} />
          <Route path="/Login" element={<Loginpage setEmail={setEmail} redirectTo="/Menu" />} />
          <Route path="/Menu" element={<Menupage email={email} />} />
          <Route path="/Create-Forms" element={<FormCreatepage email={email} />} />
          <Route path="/Show-Forms" element={<FormShow change={change} />} /> {/* Correção aqui */}
          <Route path="/Answer" element={<Answer />} />
        </>
      </Routes>
    </BrowserRouter>
  );
}


export default App;

