import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import carbon_image from '../../assets/img/carbon_image.svg';
import './Answer.css';
import Adress from './../../adress.js';
import jekforms from '../../assets/img/jekforms.svg';

function Answer() {
  const [respostas, setRespostas] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const formularioId = params.get('formulario_id');
  const email = params.get('email');

  useEffect(() => {
    const fetchRespostas = async () => {
      try {
        const response = await axios.get(`${Adress.backendUrl}/api/get_answer/${formularioId}`);
        setRespostas(response.data);
      } catch (error) {
        console.error('Erro ao obter as respostas:', error);
      }
    };

    // Chama a função para buscar as respostas
    fetchRespostas();
  }, [formularioId]);

  function returnToMenu() {
    const createURL = `/menu?email=${email}`;
    navigate(createURL);
  }

  // Função para agrupar as respostas por ID
  const groupByRespostaId = (respostas) => {
    const groupedRespostas = {};
    respostas.forEach((resposta) => {
      const { id } = resposta;
      if (!groupedRespostas[id]) {
        groupedRespostas[id] = [];
      }
      groupedRespostas[id].push(resposta);
    });
    return groupedRespostas;
  };

  const groupedRespostas = groupByRespostaId(respostas);

  return (
    <div className="Answer">
      <img src={jekforms} alt="jekforms logo" className="jekformsA" onClick={returnToMenu} />
      <div className="contentA">
        {Object.keys(groupedRespostas).map((respostaId) => (
          <div key={respostaId} className="resposta-group">
           
            {groupedRespostas[respostaId].map((resposta) => (
              <div key={resposta.id} className="resposta-item">
                <p>Pergunta: {resposta.pergunta}</p>
                <p>Resposta: {resposta.resposta}</p>
                {resposta.imagem && (
                  <img src={`${Adress.backendUrl}${resposta.imagem}`} alt="Imagem de Resposta" />
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Answer;
