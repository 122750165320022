import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { BsCheckCircle } from 'react-icons/bs';
import Adress from './../../adress.js';
import jekforms from '../../assets/img/jekforms.svg';
import logojek2 from '../../assets/img/logojek2.svg';
import caixacheck from '../../assets/img/caixacheck.svg';
import caixacheckada from '../../assets/img/caixacheckada.svg';
import Continuarbtn from '../../assets/img/Continuarbtn.svg';
import './Template3op.css';

const Template3op = ({ titulo, perguntasCheckbox, formulario_id }) => {
  const [checkedItems, setCheckedItems] = useState({});
  const [mudar, setMudar] = useState(false);
  const navigate = useNavigate();

  const handleCheckboxChange = (perguntaId, opcaoId) => {
    setCheckedItems((prevState) => ({
      ...prevState,
      [perguntaId]: opcaoId,
    }));
  };

  const isOptionChecked = (perguntaId, opcaoId) => {
    return checkedItems[perguntaId] === opcaoId;
  };

 

  const handleOptionClick = (perguntaId, opcaoId) => {
    const newCheckedItems = { ...checkedItems };

    if (newCheckedItems[perguntaId] === opcaoId) {
      
      newCheckedItems[perguntaId] = null;
    } else {
      
      newCheckedItems[perguntaId] = opcaoId;
    }

    setCheckedItems(newCheckedItems);
  };

  const handleContinuarClick = async () => {
    try {
      const formData = new FormData();
      perguntasCheckbox.forEach((pergunta) => {
        const opcaoSelecionada = pergunta.opcaoperguntacheckbox_set.find((opcao) =>
          isOptionChecked(pergunta.id, opcao.id)
        );
        if (opcaoSelecionada) {
          formData.append('formulario_id', formulario_id);
          formData.append('pergunta', titulo);
          formData.append('resposta', opcaoSelecionada.escolha);
        }
      });

      const response = await axios.post(`${Adress.backendUrl}/api/answer-Form/`, formData);

      console.log(response.data);
      console.log(formulario_id);
      
      navigate(`/Show-Forms?formulario_id=${formulario_id}&change=${mudar}`);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="Templateop3">
      <img src={jekforms} alt="jekforms" className="jekformsop3" />

      <div className="contentOP3">
        <h2 className="perguntatitle">
          <span className="blue-text">{titulo}</span>
        </h2>

        <div className="checkbox">
          {perguntasCheckbox.map((pergunta) => (
            <div key={pergunta.id} className="pergunta-container">
              {pergunta.opcaoperguntacheckbox_set.map((opcao) => (
                <div key={opcao.id}>
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      hidden
                      checked={isOptionChecked(pergunta.id, opcao.id)}
                      onChange={() => handleCheckboxChange(pergunta.id, opcao.id)}
                    />
                    <button
                      className="caixacheck"
                      onClick={() => handleOptionClick(pergunta.id, opcao.id)}
                    >
                      {opcao.escolha}
                      {isOptionChecked(pergunta.id, opcao.id) && (
                        <div className="checkedbox1">
                          <BsCheckCircle />
                        </div>
                      )}
                    </button>
                  </label>
                </div>
              ))}
            </div>
          ))}
        </div>

        <img src={Continuarbtn} onClick={handleContinuarClick} className="ContinuarBtnop3" alt="Continuar" />
      </div>

      <img src={logojek2} alt="logojek" className="logojekOP3" />
    </div>
  );
};

export default Template3op;
