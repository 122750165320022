// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import App from './App';


// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

/*index.js*/

import React from 'react';
import ReactDOM from 'react-dom/client';
import { GoogleOAuthProvider } from '@react-oauth/google';
import App from './App';
import Loginpage from './pages/loginpage/Loginpage';

ReactDOM.createRoot(document.getElementById('root')).render(
  <GoogleOAuthProvider clientId="863812812346-1kn9rp3294v4dnb9gn2rr8tf33jpbhmj.apps.googleusercontent.com">
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </GoogleOAuthProvider>
);






