import React, { useState, useEffect } from 'react';
import axios from 'axios';
import jekforms from '../../assets/img/jekforms.svg';
import carbon_image2 from '../../assets/img/carbon_image2.svg';
import Continuarbtn from '../../assets/img/Continuarbtn.svg';



import Adress from './../../adress.js';
import './Template1.css';

const Template1 = ({ buttonPressed, formularioId }) => {
  const [inputValue, setInputValue] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);

  const handleInputChange = (event) => {
    if (!buttonPressed) {
      setInputValue(event.target.value);
    }
  };

  const handleImageChange = (event) => {
    setSelectedImage(event.target.files[0]);
  };

  useEffect(() => {
    if (buttonPressed && inputValue) {
      enviarInputs();
    }
  }, [buttonPressed]);

  const enviarInputs = async () => {
    try {
      const formData = new FormData();
      formData.append('tipo', '1');
      formData.append('titulo', inputValue);
      formData.append('formularioId', formularioId);
      formData.append('imagem', selectedImage);

      console.log(selectedImage)

      const response = await axios.post(`${Adress.backendUrl}/api/criar-Template/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log('Inputs enviados para a base de dados');
      console.log('Valor do input:', inputValue);
      console.log('Resposta da API:', response.data);
      // Lógica adicional após enviar os inputs para a base de dados
      setInputValue('');
    } catch (error) {
      console.error('Erro ao enviar os inputs para a base de dados:', error);
    }
  };

  return (
    <div className='Template1'>
      <img src={jekforms} alt="jekforms" className="jekformsT1" />

      <div className='Tlinha'>
        <label htmlFor="imagemInput">
          <img src={carbon_image2} alt="pngimagem" className="carbon_image" />
        </label>
        <input
          type="file"
          id="imagemInput"
          accept="image/*"
          style={{ display: 'none' }}
          onChange={handleImageChange}
        />
      </div>
      <div className='content'>
        <input type="text" name="inputValue" className="templateInput" placeholder="Título" value={inputValue} onChange={handleInputChange}/>
        <img src={Continuarbtn} alt="Continua" className="ContinuarBtn" />
      </div>
    </div>
  );
};

export default Template1;
