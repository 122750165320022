import React, { useState, useEffect } from 'react';
import axios from 'axios';
import jekforms from '../../assets/img/jekforms.svg';
import Downbutton from '../../assets/img/carbon_image3.svg';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button/Button';


import Adress from './../../adress.js';
import './Template7op.css';

const Template7op = ({ titulo, formulario_id}) => {
  const [imagemSelecionada, setImagemSelecionada] = useState(null);
  const navigate = useNavigate();
  const [mudar, setmudar] = useState(false)

  const handleImageChange = (event) => {
    setImagemSelecionada(event.target.files[0]);
  };

  useEffect(() => {
    if (mudar) {
      navigate(`/Show-Forms?formulario_id=${formulario_id}&change=${mudar}`);
    }
  }, [mudar, formulario_id, navigate]);


  const enviarArquivo = async () => {
    try{

      const formData = new FormData();
      
      formData.append('formulario_id', formulario_id);
      formData.append('pergunta', titulo);
      formData.append('imagem', imagemSelecionada);
      console.log(formulario_id)
      console.log(titulo)
      console.log(imagemSelecionada)
      
      console.log(formData)

        const response = await axios.post(`${Adress.backendUrl}/api/answer-Form/`, formData, {
      
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

      console.log('Resposta da API:', response.data);
      console.log('Resposta enviada para a base de dados');
      setImagemSelecionada(null);
      setmudar(true);
      
    } catch (error) {
      console.error('Erro ao enviar a resposta para a base de dados:', error);
    }
  };

  return (
    <div className='Template7op'>
      <img src={jekforms} alt="jekforms" className="jekformsOP7" />  
      
      <div className='contentOP7'>
        <h2 className='titulotop7'>
          <span className="blue-text">{titulo}</span>
        </h2>
        <div className="quadradoWrapperOP7">
          <label htmlFor="imagemInput">
            <img
              src={Downbutton}
              alt="pngimagem"
              className="DownBOP7"
            />
          </label>
          <input
            type="file"
            id="imagemInput"
            accept="image/*"
            style={{ display: 'none' }}
            onChange={handleImageChange}
          />
        </div>
        
      </div>

      <div className="Button-containerop7" onClick={enviarArquivo}>
            <Button className="Buttonop7" />
      </div>
    </div>
  );
};

export default Template7op;
