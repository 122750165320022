import React, { useState, useEffect } from 'react';
import axios from 'axios';
import jekforms from '../../assets/img/jekforms.svg';
import carbon_image from '../../assets/img/carbon_image.svg';

import Adress from './../../adress.js';
import './Template6.css';

const Template6 = ({ buttonPressed, formularioId }) => {
  const [inputValue, setInputValue] = useState('');
  const [imagem1, setImagem1] = useState(null);
  const [imagem2, setImagem2] = useState(null);
  const [imagem3, setImagem3] = useState(null);
  const [imagem1temp, setImagem1temp] = useState(null);
  const [imagem2temp, setImagem2temp] = useState(null);
  const [imagem3temp, setImagem3temp] = useState(null);
  const [currentImage, setCurrentImage] = useState(carbon_image);

  const handleImageChange = (index, event) => {
    const imagem = event.target.files[0];
    const imageURL = URL.createObjectURL(imagem);

    if (index === 1) {
      setImagem1temp(imageURL);
      setImagem1(imagem);
    } else if (index === 2) {
      setImagem2temp(imageURL);
      setImagem2(imagem);
    } else if (index === 3) {
      setImagem3temp(imageURL);
      setImagem3(imagem);
    }
  };

  const handleInputChange = (event) => {
    if (!buttonPressed) {
      setInputValue(event.target.value);
    }
  };

  useEffect(() => {
    if (buttonPressed && inputValue) {
      enviarImagens();
    }
  }, [buttonPressed]);

  const enviarImagens = async () => {
    try {
      const formData = new FormData();
      formData.append('tipo', '6');
      formData.append('titulo', inputValue);
      formData.append('formularioId', formularioId);
      formData.append('imagem1', imagem1);
      formData.append('imagem2', imagem2);
      formData.append('imagem3', imagem3);

      const response = await axios.post(`${Adress.backendUrl}/api/criar-Template/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log('Inputs enviados para a base de dados');
      console.log('Valor do input:', inputValue);
      console.log('Resposta da API:', response.data);
      setInputValue('');
      setImagem1(null);
      setImagem2(null);
      setImagem3(null);
      setImagem1temp(null);
      setImagem2temp(null);
      setImagem3temp(null);
    } catch (error) {
      console.error('Erro ao enviar os inputs para a base de dados:', error);
    }
  };

  return (
    <div className='Template6'>
      <img src={jekforms} alt="jekforms" className="jekformsT6" />

      <div className='contentT6'>
        <input type="text" name="Titulo" placeholder="Título da pergunta" value={inputValue} onChange={handleInputChange}/>
      </div>

      <div className="quadradoWrapper">
      <label htmlFor="imagemInput_1" className="QA1">
        <img src={imagem1temp || carbon_image} alt="Imagem 1"  />
      </label>
      <input
        type="file"
        id="imagemInput_1"
        accept="image/*"
        style={{ display: 'none' }}
        onChange={e => handleImageChange(1, e)}
      />

      <label htmlFor="imagemInput_2" className='QA2'>
        <img src={imagem2temp || carbon_image} alt="Imagem 2"  />
      </label>
      <input
        type="file"
        id="imagemInput_2"
        accept="image/*"
        style={{ display: 'none' }}
        onChange={e => handleImageChange(2, e)}
      />

      <label htmlFor="imagemInput_3" className="QA3">
        <img src={imagem3temp || carbon_image} alt="Imagem 3" />
      </label>
      <input
        type="file"
        id="imagemInput_3"
        accept="image/*"
        style={{ display: 'none' }}
        onChange={e => handleImageChange(3, e)}
      />
        
      </div>
    </div>
  );
};

export default Template6;
