import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; 
import jekforms from '../../assets/img/jekforms.svg';
import Continuarbtn from '../../assets/img/Continuarbtn.svg';
import Adress from './../../adress.js';
import './Template1op.css';

const Template1op = ({formulario_id, titulo, imagem }) => {
  const [inputValue, setInputValue] = useState('');
  const navigate = useNavigate(); 
  console.log('Valor da prop imagem:', imagem);
  const imagemUrl = `${Adress.backendUrl}${imagem}`;
  const [mudar, setmudar] = useState(false)

  const handleMudarTemplate = () => {
    console.log(formulario_id)
    setmudar(true)
    navigate(`/Show-Forms?formulario_id=${formulario_id}&change=${mudar}`);

  };

  return (
    <div className='Template1OP1'>


      <img src={jekforms} alt="jekforms" className="jekformsTOP1" />
      <div className='contentOP1'>
              <h1>{titulo}</h1>
                <button alt="Continua" className="ContinuarBtnOP1"  onClick={handleMudarTemplate}>Continuar</button>
            </div>

            <div className='TlinhaOP1'>
        <img src={imagemUrl} alt='imagemnzinha'/>
     </div>


    </div>
  );
};

export default Template1op;
