import React, { useState, useEffect} from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import jekforms from '../../assets/img/jekforms.svg';
import Calendar from 'react-calendar';
import Button from '../../components/Button/Button';

import Adress from './../../adress.js';
import './Template5op.css';

const Template5op = ({ titulo, formulario_id}) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [mudar, setmudar] = useState(false)
  const navigate = useNavigate();

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  
  
  useEffect(() => {
    if (mudar) {
      console.log(formulario_id);
      navigate(`/Show-Forms?formulario_id=${formulario_id}&change=${mudar}`);
    }
  }, [mudar, formulario_id, navigate]);

  const enviarDataParaBaseDeDados = async () => {
    try {
     
      const formattedDate = selectedDate.toISOString();

      const formData = new FormData();

      formData.append('formulario_id', formulario_id);
      formData.append('pergunta', titulo);
      formData.append('resposta', formattedDate);

    const response = await axios.post(`${Adress.backendUrl}/api/answer-Form/`, formData);

      console.log('Data enviada para a base de dados:', formattedDate);
      console.log('Resposta da API:', response.data);
      navigate(`/Show-Forms?formulario_id=${formulario_id}&change=${mudar}`);
    } catch (error) {
      console.error('Erro ao enviar a data para a base de dados:', error);
    }
  };

  return (
    <div className='Template5op'>
      <img src={jekforms} alt="jekforms" className="jekformsOP5" />

      <div className='contentOP5'>
        <h2 className='titulotop5'> <span className="blue-text">{titulo}</span> </h2>

        <div className="quadradoWrapperOP5">
          <div className='calendar'>
            <Calendar onChange={handleDateChange} value={selectedDate} />
          </div>


        </div>
      </div>
      <div className="Button-containerop5" onClick={enviarDataParaBaseDeDados}>
            <Button className="Button" />
        </div>
    </div>
  );
};

export default Template5op;
